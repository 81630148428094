// OpenModels.jsx
import React from "react";
import styles from './OpenModels.module.scss';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const OpenModels = () => {
  const theme = useTheme();
  const isBetween770and1300 = useMediaQuery('(min-width:770px) and (max-width:1300px)');

  const topCardsData = [
    {
      id: 1,
      image: './meta-color.svg',
      title: 'LIama 3.1 8B',
      tag: 'Chat',
      hideBetween770and1300: true, // Скрываем в заданном диапазоне
    },
    {
      id: 2,
      image: './mistral-color.svg',
      title: 'Mistral 7B',
      tag: 'Chat',
      // Будем динамически перемещать эту карточку
    }
  ];

  const bottomCardsData = [
    {
      id: 1,
      image: './mistral-color.svg',
      title: 'Mixtral 8x7B',
      tag: 'Chat'
    },
    {
      id: 2,
      image: './meta-color.svg',
      title: 'LIama 3.1 70B',
      tag: 'Chat'
    },
    {
      id: 3,
      image: './meta-color.svg',
      title: 'LIama 3.1 405B',
      tag: 'Chat',
      
    },
    {
      id: 4,
      image: './mistral-color.svg',
      title: 'Mixtral 8x22B',
      tag: 'Chat'
    },
    {
      id: 5,
      image: './deepseek-color.svg',
      title: 'Deepseek R1',
      tag: 'Chat'
    },
    {
      id: 6,
      image: './google-color.svg',
      title: 'Gemma 2 27B',
      tag: 'Chat'
    },
    {
      id: 7,
      image: './meta-color.svg',
      title: 'Codellama 34B',
      tag: 'code',
      hideBetween770and1300: true, // Скрываем в заданном диапазоне
    },
    {
      id: 8,
      image: './deepseek-color.svg',
      title: 'Deepseek V3',
      tag: 'chat'
    },
    {
      id: 9,
      image: './deepseek-color.svg',
      title: 'Deepseek Coder',
      tag: 'chat'
    },
    {
      id: 10,
      image: './plus.svg',
      title: 'and more!',
      tag: '100+ LLMs supported'
    }
  ];

  // Динамическое формирование массивов карточек для отображения
  let topCardsToDisplay = topCardsData;
  let bottomCardsToDisplay = bottomCardsData;

  if (isBetween770and1300) {
    // Скрываем 'LIama 3.1 8B' и 'Mistral 7B' в верхних карточках
    topCardsToDisplay = topCardsData.filter(card => card.title !== 'LIama 3.1 8B' && card.title !== 'Mistral 7B');

    // Ищем карточку 'Mistral 7B' и добавляем ее в начало нижних карточек
    const mistral7BCard = topCardsData.find(card => card.title === 'Mistral 7B');
    if (mistral7BCard) {
      bottomCardsToDisplay = [mistral7BCard, ...bottomCardsData];
    }
  }

  // Функция для обработки клика на карточку
  const handleCardClick = () => {
    window.open('https://platform.primeway.io/docs', '_blank');
  };

  return (
    <div className={styles.container}>
      <div className={styles.topSection}>
        <div className={styles.text}>
          <h1 className={styles.h1}>
            Запускайте все, что угодно, что <br /> может быть запущено в докере
          </h1>
          <p className={styles.paragraph}>
            Деплойте и дообучайте open source модели вместе с <br />
            PrimeWay, чтобы сделать это максимально эффективно
          </p>
        </div>
        <div className={styles.cardsRight}>
          {topCardsToDisplay.map(card => (
            <div
              key={card.id}
              className={`${styles.card} hover-target ${card.hideBetween770and1300 ? styles.hideBetween770and1300 : ''}`}
              onClick={handleCardClick}
            >
              <img src={card.image} alt={card.title} />
              <p>{card.title}</p>
              <span>{card.tag}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.bottomSection}>
        {bottomCardsToDisplay.map(card => (
          <div
            key={`${card.id}-${card.title}`}
            className={`${styles.card} hover-target ${card.hideBetween770and1300 ? styles.hideBetween770and1300 : ''}`}
            onClick={handleCardClick}
          >
            <img src={card.image} alt={card.title} />
            <p>{card.title}</p>
            <span>{card.tag}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OpenModels;