import React, { useState, useEffect, useRef } from "react";
import styles from "./Header.module.scss";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef(null);
  const menuIconRef = useRef(null); 

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navRef.current &&
        !navRef.current.contains(event.target) &&
        menuIconRef.current &&
        !menuIconRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img src="../primeway-logo-back.svg" width={50} height={50} alt="Logo" />
          <h2>PrimeWay</h2>
        </div>
        <div
          className={styles.menuIcon}
          onClick={handleToggle}
          ref={menuIconRef}
        >
          <div className={styles.burger}></div>
          <div className={styles.burger}></div>
          <div className={styles.burger}></div>
        </div>
        <nav
          className={`${styles.navLinks} ${isOpen ? styles.active : ""}`}
          ref={navRef}
        >
          <a href="https://platform.primeway.io" target="_blank" rel="noopener noreferrer">
            <p className={styles.p}>Платформа</p>
          </a>
          <a href="https://platform.primeway.io/docs" target="_blank" rel="noopener noreferrer">
            <p className={styles.p}>Документация</p>
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;