import React from "react";
import styles from "./Content.module.scss";
import GPUList from "../GPUList/GPUList";
import Workflow from "../Workflow/Workflow";
import OpenModels from "../OpenModels/OpenModels";
import FAQ from "../FAQ/FAQ";
import "./shape.css";
import { useMediaQuery, useTheme } from "@mui/material";

const Content = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <main className={styles.main}>
      <h1 className={styles.h1}>
        Запускайте и деплойте проекты машинного обучения на <br /> бессерверных
        GPU -{" "}
        <span
          style={{
            background: "linear-gradient(to right, #00FF37 0%, #054CFF 100%)",
            WebkitBackgroundClip: "text",
            color: "transparent",
          }}
        >
          всего одной <br /> CLI командой
        </span>
      </h1>

      {/* {!(isMobile || isTablet) && (
        <div className="floating-shapes">
          <img
            src="../svgviewer-1.svg"
            alt="Shape 1"
            className="floating-shape shape-1"
          />
          <img
            src="../svgviewer-2.svg"
            alt="Shape 2"
            className="floating-shape shape-2"
          />
          <img
            src="../svgviewer-3.svg"
            alt="Shape 3"
            className="floating-shape shape-3"
          />
          <img
            src="../svgviewer-4.svg"
            alt="Shape 4"
            className="floating-shape shape-4"
          />
          <img
            src="../svgviewer-5.svg"
            alt="Shape 5"
            className="floating-shape shape-5"
          />
          
        </div>
      )} */}

      <p className={styles.p}>
        Автомасштабирование к нулю opens source моделей во время отсутствия
        запросов, планирование расписания работы задач <br /> дообучения, деплоя
        и других всего одним параметром в конфиге.
        <br /> - <br />
        Вам не нужно знать ничего об облачных технологиях и devops для создания
        экономичных и эффективных ml систем
      </p>
      <button
        onClick={() => window.open("https://platform.primeway.io", "_blank")}
        className={styles.MlButton}
      >
       Начните создавать великолепные ml приложения
      </button>

      <div className={styles.imgContainer}>
        <img
          className={styles.imgTop}
          src="../Screenshot1.png"
          alt="platform"
        />
        <img className={styles.imgBottom} src="../Screenshot2.png" alt="code" />
      </div>
      <h2 className={styles.h2}>
        Вы платите только за время работы <br /> контейнера
      </h2>
      <p className={styles.pInfo}>
        {" "}
        Это значит, вам не нужно тратить время и деньги на настройку <br />{" "}
        сервера и загрузку докер образа. Биллинг включается только после того,
        как контейнер с образом запустился
      </p>

      <GPUList />

      <Workflow />

      <div className={styles.quote}>
        <p>
          Готово! Теперь у вас есть запущенная open source llm модель <br /> от
          tbank, одна из лучших на русском языке, с расписанием, когда она
          должна работать, и <br /> автомасштабированием к нулю после 15 минут
          без запросов и поднятием во время нового
        </p>
      </div>

      <OpenModels />

      <FAQ />
    </main>
  );
};

export default Content;
