import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Tooltip,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import NvidiaIcon from "./NvidiaIcon";

const gpuData = [
  {
    id: 1,
    name: "A100 PCIe",
    memoryInGb: 80,
    costPerHour: 260,
  },
  {
    id: 2,
    name: "A100 SXM",
    memoryInGb: 80,
    costPerHour: 299,
  },
  {
    id: 3,
    name: "A40",
    memoryInGb: 48,
    costPerHour: 90,
  },
  {
    id: 4,
    name: "RTX 4090",
    memoryInGb: 24,
    costPerHour: 130,
  },
  {
    id: 5,
    name: "H100 NVL",
    memoryInGb: 94,
    costPerHour: 355,
  },
  {
    id: 6,
    name: "H100 PCIe",
    memoryInGb: 80,
    costPerHour: 355,
  },
  {
    id: 7,
    name: "H200 SXM",
    memoryInGb: 143,
    costPerHour: 460,
  },
  {
    id: 8,
    name: "L4",
    memoryInGb: 24,
    costPerHour: 90,
  },
  {
    id: 9,
    name: "L40S",
    memoryInGb: 48,
    costPerHour: 175,
  },
  {
    id: 10,
    name: "RTX 2000 Ada",
    memoryInGb: 16,
    costPerHour: 55,
  },
  {
    id: 11,
    name: "RTX 6000 Ada",
    memoryInGb: 48,
    costPerHour: 140,
  },
  {
    id: 14,
    name: "H100 SXM",
    memoryInGb: 80,
    costPerHour: 399,
  },
  {
    id: 12,
    name: "RTX A6000",
    memoryInGb: 48,
    costPerHour: 130,
  },
];

const GPUList = () => {
  // Состояние для отслеживания, какой GPU был скопирован
  const [copiedGpuId, setCopiedGpuId] = useState(null);

  // Используем хук useTheme и useMediaQuery для определения мобильного устройства
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  // Создаем массив GPU для отображения, возможно, без последнего элемента на мобильных устройствах
  const displayedGpuData = isMobile ? gpuData.slice(0, -1) : gpuData;

  // Функция копирования имени GPU
  const handleCopy = (gpu) => {
    navigator.clipboard.writeText(gpu.name).then(() => {
      setCopiedGpuId(gpu.id);

      // Сбрасываем состояние через 2 секунды
      setTimeout(() => {
        setCopiedGpuId(null);
      }, 2000);
    });
  };

  return (
    <Box sx={{ marginTop: "60px", padding: isMobile ? "0 16px" : "0 10px"}}>
      {/* Сетка Grid с учетом мобильных устройств */}
      <Grid
        container
        spacing={isMobile ? 0.5 : 2}
        columns={isMobile ? 2 : isTablet ? 3 : 5}
        alignItems="center"
      >
        {/* Текстовый блок */}
        <Grid item xs={isMobile ? 2 : 5} sm={isMobile ? 2 : 2}>
          <Typography
            sx={{
              fontFamily: "Jura, serif",
              fontWeight: "700",
              fontSize: { xs: "20px", sm: "34px" },
              textAlign: isMobile ? "center" : "left",
              background: "linear-gradient(to right, #06E528 0%, #6606F5 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              color: "transparent",
              marginBottom: isMobile ? "20px" : "0",
            }}
          >
            То, чего нет у других облаков — и дешевле, то что есть
          </Typography>
        </Grid>

        {/* Карточки GPU */}
        {displayedGpuData.map((gpu) => (
          <Grid item key={gpu.id} xs={isMobile ? 1 : 1} sm={isMobile ? 1 : 1}>
            <Tooltip
              title={copiedGpuId === gpu.id ? "Имя скопировано!" : ""}
              open={copiedGpuId === gpu.id}
              placement="top"
              arrow
              disableHoverListener
              disableFocusListener
              disableTouchListener
            >
              <Card
                elevation={0}
                className="hover-target"
                sx={{
                  position: "relative",
                  backgroundColor: "#060606",
                  color: "#fff",
                  borderRadius: "15px",
                  transition: "transform 0.3s, box-shadow 0.3s",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  cursor: "pointer",
                  "&:hover .copy-icon": {
                    opacity: 1,
                  },
                  // Стили для мобильных устройств
                  ...(isMobile && {
                    padding: "10px",
                    transform: "scale(0.9)",
                  }),
                }}
                onClick={() => handleCopy(gpu)}
              >
                {/* Иконка копирования */}
                <IconButton
                  className="copy-icon"
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    opacity: 0,
                    transition: "opacity 0.3s",
                    color: "#fff",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopy(gpu);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>

                <CardContent
                  sx={{
                    padding: isMobile ? "10px" : "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "8px",
                  }}
                >
                  <NvidiaIcon
                    width={isMobile ? 40 : 60}
                    height={isMobile ? 40 : 60}
                  />

                  <Typography
                    sx={{
                      fontSize: isMobile ? "16px" : "18px",
                      fontWeight: "bold",
                    }}
                    component="div"
                    gutterBottom
                  >
                    {gpu.name}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: isMobile ? "14px" : "16px",
                    }}
                  >
                    <strong>Память:</strong> {gpu.memoryInGb} GB
                  </Typography>

                  <Box
                    sx={{
                      color: "#0bff04",
                      padding: "5px 20px",
                      borderRadius: "5px",
                      fontSize: isMobile ? "15px" : "18px",
                      marginTop: "8px",
                    }}
                  >
                    <strong>{gpu.costPerHour}</strong> ₽/час
                  </Box>
                </CardContent>
              </Card>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GPUList;
