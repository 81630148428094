import React from "react";
import styles from "./UserAgreement.module.scss";

const UserAgreement = () => {
  return (
    <div className={styles.main}>
      <h1>Пользовательское соглашение</h1>
      <div className={styles.mainText}>
        <p>Последнее обновление: 31.01.2025</p>

        <h2>1. Общие положения</h2>
        <p>
          1.1. Настоящее Пользовательское соглашение (далее — "Соглашение")
          регулирует отношения между Обществом с ограниченной ответственностью
          "МыЗдесь" (ИНН 7802951652), далее именуемым "Компания", и
          физическим или юридическим лицом, использующим сервис
          "PrimeWay" (далее — "Сервис"), далее именуемым "Пользователь".
        </p>
        <p>
          1.2. Пользователь, используя Сервис, подтверждает свое согласие с
          условиями настоящего Соглашения в полном объеме без каких-либо
          оговорок и исключений.
        </p>
        <p>
          1.3. Если Пользователь не согласен с каким-либо из условий
          Соглашения, он обязан незамедлительно прекратить использование
          Сервиса.
        </p>

        <h2>2. Предмет Соглашения</h2>
        <p>
          2.1. Компания предоставляет Пользователю доступ к Сервису,
          позволяющему удаленно запускать задачи, требующие использования GPU,
          включая обучение моделей, развертывание API-сервисов, дообучение и
          создание конвейеров обработки данных.
        </p>
        <p>
          2.2. Сервис предоставляет возможность автоматического масштабирования,
          планирования задач и управления конфигурациями без необходимости
          глубоких знаний в области DevOps.
        </p>
        <p>
          2.3. Пользователь самостоятельно определяет параметры конфигурации
          задач, включая тип и количество GPU, базовый образ, команды для
          выполнения и другие необходимые настройки.
        </p>

        <h2>3. Регистрация и учетная запись</h2>
        <p>
          3.1. Для доступа к полному функционалу Сервиса Пользователь должен
          пройти процедуру регистрации, предоставив достоверную и актуальную
          персональную информацию.
        </p>
        <p>
          3.2. Пользователь несет ответственность за сохранность
          конфиденциальности своих учетных данных и обязан предпринимать
          необходимые меры для предотвращения несанкционированного доступа к
          своей учетной записи.
        </p>
        <p>
          3.3. Компания не несет ответственности за действия третьих лиц,
          получивших доступ к учетной записи Пользователя по его вине.
        </p>

        <h2>4. Персональные данные</h2>
        <p>
          4.1. При регистрации и использовании Сервиса Пользователь предоставляет
          Компании свои персональные данные, необходимые для выполнения условий
          настоящего Соглашения.
        </p>
        <p>
          4.2. Обработка персональных данных Пользователя осуществляется в
          соответствии с Федеральным законом РФ "О персональных данных" № 152-ФЗ
          от 27 июля 2006 года.
        </p>
        <p>
          4.3. Компания обязуется не передавать персональные данные Пользователя
          третьим лицам без его согласия, за исключением случаев,
          предусмотренных законодательством РФ.
        </p>

        <h2>5. Неконфиденциальность передаваемой информации</h2>
        <p>
          5.1. Пользователь подтверждает, что все данные, модели, коды и
          материалы, загружаемые или передаваемые через Сервис, не являются
          конфиденциальными и не содержат коммерческой тайны, персональных
          данных третьих лиц или информации, защищенной авторским правом без
          соответствующего разрешения.
        </p>
        <p>
          5.2. Пользователь несет полную ответственность за содержание
          передаваемой информации и гарантирует, что ее использование не
          нарушает прав третьих лиц и применимого законодательства.
        </p>
        <p>
          5.3. Компания не несет ответственности за содержание информации,
          передаваемой Пользователем через Сервис.
        </p>

        <h2>6. Хранение и обработка данных</h2>
        <p>
          6.1. Пользователь соглашается, что передаваемые им данные могут
          храниться и обрабатываться на серверах, расположенных как на
          территории Российской Федерации, так и за ее пределами.
        </p>
        <p>
          6.2. Компания принимает необходимые организационные и технические меры
          для защиты данных Пользователя от несанкционированного доступа,
          уничтожения, изменения или распространения.
        </p>
        <p>
          6.3. Несмотря на принятые меры, Компания не может гарантировать
          абсолютную безопасность данных и не несет ответственности за действия
          третьих лиц.
        </p>

        <h2>7. Права и обязанности сторон</h2>
        <p>
          <strong>7.1. Пользователь обязуется:</strong>
        </p>
        <ul>
          <li>Соблюдать условия настоящего Соглашения и действующее законодательство РФ;</li>
          <li>Не использовать Сервис для целей, противоречащих нормам морали и права;</li>
          <li>Не предпринимать действий, направленных на нарушение работы Сервиса.</li>
        </ul>
        <p>
          <strong>7.2. Компания обязуется:</strong>
        </p>
        <ul>
          <li>Предоставлять доступ к Сервису в соответствии с условиями настоящего Соглашения;</li>
          <li>Обеспечивать конфиденциальность персональных данных Пользователя;</li>
          <li>Информировать Пользователя о существенных изменениях в Сервисе и условиях Соглашения.</li>
        </ul>

        <h2>8. Финансовые условия</h2>
        <p>
          8.1. Стоимость использования GPU и других ресурсов Сервиса устанавливается
          Компанией и может периодически изменяться.
        </p>
        <p>
          8.2. Компания оставляет за собой право изменять цены на GPU и другие услуги
          Сервиса в любое время без предварительного уведомления Пользователя.
        </p>
        <p>
          8.3. Пользователь обязуется самостоятельно отслеживать текущие цены и тарифы,
          размещенные на сайте Сервиса или в личном кабинете.
        </p>

        <h2>9. Гарантии и ответственность</h2>
        <p>
          9.1. Компания не гарантирует непрерывную и бесперебойную работу Сервиса, а
          также доступность серверов в момент, когда они необходимы Пользователю.
        </p>
        <p>
          9.2. Сервис находится в стадии бета-тестирования. Пользователь осознает и
          принимает, что Сервис может содержать ошибки, сбои и другие технические
          недостатки.
        </p>
        <p>
          9.3. Компания не несет ответственности за возможные убытки Пользователя,
          связанные с использованием Сервиса, включая, но не ограничиваясь, потерей
          данных, результатов запущенных задач, доступа к Сервису или любой другой
          информации.
        </p>
        <p>
          9.4. Пользователь принимает на себя все риски, связанные с использованием
          Сервиса в стадии бета-тестирования, и соглашается, что любые проблемы с
          работой Сервиса не подлежат компенсации.
        </p>

        <h2>10. Ограничение ответственности</h2>
        <p>
          10.1. Сервис предоставляется "как есть". Компания не гарантирует соответствие
          Сервиса ожиданиям Пользователя и безошибочную работу Сервиса.
        </p>
        <p>
          10.2. Компания не несет ответственности за прямые или косвенные убытки
          Пользователя, возникшие в результате использования или невозможности
          использования Сервиса.
        </p>
        <p>
          10.3. Пользователь самостоятельно несет ответственность за возможные
          последствия использования Сервиса, включая потерю данных или ущерб
          оборудованию.
        </p>

        <h2>11. Интеллектуальная собственность</h2>
        <p>
          11.1. Все объекты, доступные через Сервис, включая элементы дизайна, текст,
          графические изображения, иллюстрации, видео, программы и прочие объекты (далее
          — "Контент"), а также сам Сервис, являются объектами исключительных прав
          Компании.
        </p>
        <p>
          11.2. Использование Контента без согласия правообладателей не допускается.
        </p>

        <h2>12. Изменения в Соглашении</h2>
        <p>
          12.1. Компания оставляет за собой право вносить изменения в настоящее
          Соглашение в одностороннем порядке.
        </p>
        <p>
          12.2. Изменения вступают в силу с момента их публикации на сайте Сервиса.
          Пользователь обязуется самостоятельно отслеживать изменения в Соглашении.
        </p>
        <p>
          12.3. Продолжение использования Сервиса после внесения изменений означает
          согласие Пользователя с новыми условиями.
        </p>

        <h2>13. Прекращение действия Соглашения</h2>
        <p>
          13.1. Настоящее Соглашение действует до его расторжения одной из сторон.
        </p>
        <p>
          13.2. Пользователь имеет право прекратить использование Сервиса в любое время.
        </p>
        <p>
          13.3. Компания имеет право приостановить или прекратить доступ Пользователя к
          Сервису в случае нарушения им условий настоящего Соглашения или
          законодательства РФ, или по неизвестным для пользователя причины, и Компания 
          имеет права не раскрывать пользователю данные причины.
        </p>

        <h2>14. Урегулирование споров</h2>
        <p>
          14.1. Все споры и разногласия, возникающие в связи с исполнением настоящего
          Соглашения, подлежат разрешению путем переговоров между сторонами.
        </p>
        <p>
          14.2. В случае недостижения соглашения споры передаются на рассмотрение в суд
          по месту нахождения Компании в соответствии с действующим законодательством
          РФ.
        </p>

        <h2>15. Заключительные положения</h2>
        <p>
          15.1. Настоящее Соглашение регулируется и толкуется в соответствии с
          законодательством Российской Федерации.
        </p>
        <p>
          15.2. Недействительность или неисполнимость какого-либо положения настоящего
          Соглашения не влияет на действительность или исполнимость остальных положений
          Соглашения.
        </p>
        <p>
          15.3. По всем вопросам, не урегулированным настоящим Соглашением, стороны
          руководствуются действующим законодательством РФ.
        </p>

        <h2>Контактная информация Компании</h2>
        <p>
          ООО "МыЗдесь"<br />
          ИНН: 7802951652<br />
          [support@primeway.io]
        </p>

        <p>
          <em>
            Настоящее Пользовательское соглашение является публичной офертой.
            Использование Сервиса означает полное и безусловное принятие
            Пользователем условий настоящего Соглашения.
          </em>
        </p>
      </div>
    </div>
  );
};

export default UserAgreement;