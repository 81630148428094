// Footer.jsx
import React from "react";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.logoSection}>
        <img
          src="../primeway-logo-back.svg"
          width={30}
          height={30}
          alt="logo"
        />
        <h1>PrimeWay</h1>
      </div>
      <p>Санкт-Петербург</p>

      <div className={styles.footerBottom}>
        <div>
          <span>
            <span className={styles.span}>©</span> 2025 PrimeWay
            <a
              href="/user-agreement"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: "40px", wordSpacing: "-4px" }}
            >
              Пользовательское соглашение
            </a>
          </span>
        </div>
        <div className={styles.icon}>
        <a
            href="https://t.me/PrimeWayio"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="hover-target"
              src="./tg.svg"
              alt="Telegram"
              width={40}
              height={37}
            />
          </a>

          {/* Email icon with mailto link */}
          <a href="mailto:support@primeway.io">
            <img
              className="hover-target"
              src="./yandex.svg"
              alt="Email"
              width={40}
              height={40}
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;