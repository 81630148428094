import React, { useState } from "react";
import styles from "./Workflow.module.scss";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";

const yamlCode = `docker_image: vllm/vllm-openai:latest
job_name: deploy_sklearn_model-v
job_type: deploy
command: ["/bin/sh", "-c"]
args: >
  vllm serve t-bank-ai/T-lite-instruct-0.1
  --served-model-name T-lite-instruct-0.1
  --max_num_batched_tokens 24000
disk_space: 40
port: 8000
volume: /root/.cache/huggingface
autoscaler_timeout: 960
gpu_types:
  - type: A40
    count: 1
env:
  - name: HUGGING_FACE_HUB_TOKEN
    value: hf_UbrilqcpTxfBFamcJhXLgBQiWdy***
health_endpoint: /health
health_check_timeout: 1500
schedule:
  workdays:
    - start: "09:00:00"
      end: "17:00:00"
  weekends:
    - start: "10:00:00"
      end: "16:00:00"
  specific_days:
    - day: 5
      windows:
        - start: "00:00:00"
          end: "23:59:59"`;

const Workflow = () => {
  // Состояния для управления отображением тултипов
  const [copiedButton, setCopiedButton] = useState(null);

  // Функция копирования текста и отображения тултипа
  const handleCopy = (text, buttonId) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedButton(buttonId);

      // Скрываем тултип через 2 секунды
      setTimeout(() => {
        setCopiedButton(null);
      }, 2000);
    });
  };

  return (
    <div className={styles.main}>
      <h1 className={styles.h1}>Ваш воркфлоу для быстрого старта</h1>

      <p className={styles.p}>1. Установите библиотеку</p>
      <div className={styles.buttonContainer}>
        <div className={styles.buttonWrapper}>
          <div className={styles.circles}>
            <span className={`${styles.circle} ${styles.red}`}></span>
            <span className={`${styles.circle} ${styles.yellow}`}></span>
            <span className={`${styles.circle} ${styles.green}`}></span>
          </div>
          <button
            className={styles.btn}
            onClick={() => handleCopy("pip install primeway", "btn1")}
          >
            pip install primeway
          </button>
          {copiedButton === "btn1" && (
            <div className={styles.tooltip}>Скопировано!</div>
          )}
        </div>
      </div>

      <p className={styles.p}>2. Создайте конфиг файл tlite.yaml</p>
        <SyntaxHighlighter
          customStyle={{
            fontSize: "16px",
            background: "rgb(239,239,239)",
            
            borderRadius: "20px",
          }}
          codeTagProps={{ style: { color: "#191919" } }}
          showLineNumbers
          language="yaml"
          style={coy}
          wrapLongLines={true}
        >
          {yamlCode}
        </SyntaxHighlighter>

      <p className={styles.p}>3. Создайте и запустите задачу</p>
      <div className={styles.buttonContainer}>
        <div className={styles.buttonWrapper}>
          <div className={styles.circles}>
            <span className={`${styles.circle} ${styles.red}`}></span>
            <span className={`${styles.circle} ${styles.yellow}`}></span>
            <span className={`${styles.circle} ${styles.green}`}></span>
          </div>
          <button
            className={styles.btn}
            onClick={() =>
              handleCopy(
                "primeway create job --config tlite.yaml --run",
                "btn2"
              )
            }
          >
            primeway create job --config tlite.yaml --run
          </button>
          {copiedButton === "btn2" && (
            <div className={styles.tooltip}>Скопировано!</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Workflow;
